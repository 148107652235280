<template>
  <ShadowCard
    :param="{
      name: 'Sell Wizard',
    }"
  >
    <form-wizard
      shape="tab"
      color="var(--sell-color)"
      :hideButtons="buttonDisplaySell"
      :ref="'sellwizardref'"
      @update:startIndex="updateWizardIndex()"
      title=""
      subtitle=""
    >
      <tab-content title="Assets" :before-change="firstValidate">
        <WizardComponent
          v-bind:param="param1"
          v-bind:selectedSecurities="param1"
        />
      </tab-content>
      <tab-content title="Choose Criteria" :before-change="secondValidate">
        <b-card
          class="mt-3 wizard"
          header="By which criteria do you want to SELL?"
          style="font-weight: 500"
        >
          <b-row class="justify-content-center">
            <b-col class="verticalPadding col-8 align-self-center">
              <v-select :options="options" v-model="PREcriteria"></v-select>
            </b-col>
          </b-row>
        </b-card>
      </tab-content>

      <tab-content title="Selection" :before-change="thirdValidate">
        <WizardComponent
          v-show="criteria == 'Client'"
          v-bind:param="paramClient"
          v-bind:selectedSecurities="param1"
        />
        <WizardComponent
          v-show="criteria == 'Bank'"
          v-bind:param="paramBank"
          v-bind:selectedSecurities="param1"
        />
        <WizardCustomComponent
          v-if="criteria == 'Portfolio'"
          v-bind:param="paramPortfolio"
        />
      </tab-content>
      <tab-content title="Assignment" :before-change="fourthValidate">
        <WizardComponent3
          v-if="criteria2 == 'Client'"
          v-bind:param="paramComponent3Client"
          v-bind:selectedSecurities="param1"
        />
        <WizardComponent3
          v-if="criteria2 == 'Bank'"
          v-bind:param="paramComponent3Bank"
          v-bind:selectedSecurities="param1"
        />
        <WizardComponent3
          v-if="criteria2 == 'Portfolio'"
          v-bind:param="paramComponent3Portfolio"
          v-bind:selectedSecurities="param1"
        />
      </tab-content>
      <tab-content title="Get Trade Order">
        <WizardComponent5 />
      </tab-content>
    </form-wizard>
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import WizardComponent from "./WizardComponent.vue";
import WizardComponent3 from "./WizardComponent3.vue";
import WizardCustomComponent from "./WizardCustomComponent.vue";
import WizardComponent5 from "./WizardComponent5.vue";
import {
  postWizard,
  getSecurities,
  getClients,
  getPortfolios,
  getBanks,
} from "../../../services/api.js";

export default {
  name: "SellWizard",
  components: {
    WizardComponent,
    WizardComponent3,
    WizardCustomComponent,
    WizardComponent5,
    ShadowCard,
  },
  data() {
    return {
      step: 0,
      options: ["Bank", "Client", "Portfolio"],
      criteria: "",
      PREcriteria: "",
      criteria2: "",
      paramS: [],
      param1: {
        buy: false,
        sell: true,
        header: "What securities do you want to SELL?",
        fields: ["index", "name", "bbg_ticker", "actions"],
        getter: getSecurities(),
        label: "name",
        setter: "SELLASSETS",
        customOption: true,
        myFilter: (option, label, search) => {
          //by default in an array of objects, vue-select will look for a label "key" (only one),the custom filter is used to determine if the provided option matchs the current search text, in order to determine if the option should be displayed.
          let temp = search.toLowerCase();
          return (
            option.bbg_ticker.toLowerCase().indexOf(temp) > -1 ||
            option.name.toLowerCase().indexOf(temp) > -1
          );
        },
      },
      paramSell: {},
      paramClient: {
        sell: true,
        header: "On which clients do you want to SELL?",
        fields: ["index", "client_id", "actions"],
        getter: getClients(),
        label: "client_id",
        setter: "SELLCLIENTS",
        customOption: false,
        myFilter: (option, label, search) => {
          //by default in an array of objects, vue-select will look for a label "key" (only one),the custom filter is used to determine if the provided option matchs the current search text, in order to determine if the option should be displayed.
          let temp = search.toLowerCase();
          return option.client_id.toLowerCase().indexOf(temp) > -1;
        },
      },
      paramBank: {
        sell: true,
        header: "On which bank do you want to SELL?",
        fields: ["index", "bank_id", "actions"],
        getter: getBanks(),
        label: "bank_id",
        setter: "SELLBANKS",
        customOption: false,
        myFilter: (option, label, search) => {
          //by default in an array of objects, vue-select will look for a label "key" (only one),the custom filter is used to determine if the provided option matchs the current search text, in order to determine if the option should be displayed.
          let temp = search.toLowerCase();
          return option.client_id.toLowerCase().indexOf(temp) > -1;
        },
      },
      paramPortfolio: {
        getter: getPortfolios(),
      },
      paramComponent3Client: {
        sell: true,
        store: "sell_clients", // store.state  data(used for columns)
        firstColumnKey: "bbg_ticker", // must be a property of rows
        columnKey: "client_id", // Key of columns that will be assign
        columnKeyDisplay: "bbg_ticker", // key of columns that will be displayed //ver bien
        rowStore: "sell_assets", // store data (used for rows )
        rowKey: "bbg_ticker", // row.key that will be assigned an object with ColumnKeys
        criteria: "client",
        sell: true,
      },
      paramComponent3Bank: {
        sell: true,
        store: "sell_banks", // store.state  data(used for columns)
        firstColumnKey: "bbg_ticker", // must be a property of rows
        columnKey: "bank_id", // Key of columns that will be assign
        columnKeyDisplay: "bank_id", // key of columns that will be displayed //ver bien
        rowStore: "sell_assets", // store data (used for rows )
        rowKey: "bbg_ticker", // row.key that will be assigned with  an object with ColumnKeys
        criteria: "bank",
        sell: true,
      },
      paramComponent3Portfolio: {
        buy: false,
        sell: true,
        store: "sell_portfolios", // store.state  data(used for columns)
        firstColumnKey: "bbg_ticker", // must be a property of rows
        columnKey: "portfolio_id", // Key of columns that will be assign
        columnKeyDisplay: "portfolio_id", // key of columns that will be displayed //ver bien
        rowStore: "sell_assets", // store data (used for rows )
        rowKey: "bbg_ticker", // row.key that will be assigned with  an object with ColumnKeys
        criteria: "portfolio",
        sell: true,
      },
    };
  },
  methods: {
    updateWizardIndex() {
      this.$nextTick(() => {
        this.step = this.$refs.sellwizardref.activeTabIndex;
      });
    },
    firstValidate() {
      if (this.securities) {
        return true;
      }
    },
    secondValidate() {
      if (this.PREcriteria) {
        if (this.PREcriteria !== this.criteria) {
          this.$store.dispatch("cleanSellPost");
        }
        this.criteria = this.PREcriteria;

        return true;
      }
    },
    thirdValidate() {
      if (Object.keys(this.sell_clients).length) {
        this.criteria2 = this.PREcriteria;

        return true;
      }
    },
    fourthValidate() {
      postMockApi("sell", this.sell_posts).then((response) => {
        if (response.status === 200) {
          this.$store.commit("SET_FORM_RESPONSE", response.data);
        }
      });
      return true;
    },
  },
  computed: {
    buttonDisplaySell() {
      // array with conditions to display buttons or not, only return the nº element that matches the tab/step you are
      let arrayStep = [];

      arrayStep[0] = !Boolean(this.securities);
      arrayStep[1] = !Boolean(this.PREcriteria);
      arrayStep[2] =
        !Boolean(this.sell_clients) || !Object.keys(this.sell_clients).length;
      arrayStep[3] = !Object.keys(this.sell_posts).length;

      return arrayStep[this.step];
    },
    securities() {
      return this.$store.state.sell_assets.length;
    },
    sell_clients() {
      let b = "sell_" + this.criteria.toLowerCase() + "s";
      return this.$store.state[b];
    },
    sell_posts() {
      return this.$store.state.sell_post;
    },
  },
};
</script>

<style>
.wizard > .card-header {
  font-weight: 500;

  font-size: 1.4rem;

  background-color: var(--sell-color);
  color: aliceblue;
}
div.checked {
  color: black;
  background-color: var(--buy-sell);
}
.verticalPadding {
  padding-bottom: 3vh;

  padding-top: 3vh;
}
span.stepTitle.active {
  font-size: 25px;
}
</style>
